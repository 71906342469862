import React, { useState } from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import hero from "../assets/img/certified_mail_hero.png"
import option1 from "../assets/img/certifiedLetters.png"
import option2 from "../assets/img/uspsTrackingIntegration.png"
import option3 from "../assets/img/dynamicTrackingPortal.png"
import option4 from "../assets/img/flexibleMailingOptions.png"
import ABanner from "../components/features/ab.component"

import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

import dynamic from "/src/assets/img/dynamic.svg"
import competetive from "/src/assets/img/technology.svg"
import service from "/src/assets/img/next-day-service.svg"
import secure from "/src/assets/img/secure.svg"
import legal from "/src/assets/img/legal.svg"
import business from "/src/assets/img/business.svg"
import government from "/src/assets/img/goverment.svg"
import individuals from "/src/assets/img/individuals.svg"

const CertifiedMail = ({ location }) => {
  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const blocks = [
    {
      id: 1,
      title: "Envelope Options",
      description: `<div style="display: flex; gap: 10px;">
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Certified Mail #10 <br /> Envelopes <br /> </span> <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw;">Accommodates up to 5 physical pages, including the cover sheet.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">Certified Mail 9 x 12 Flat Envelopes</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">Can hold up to 50 physical pages, including the cover sheet</span></div>
      </div>`,
      imgSrc: option1,
      icon: envelope,
    },
    {
      id: 2,
      title: "USPS Certification and Tracking Integration",
      description: `<div style="padding: 5px 5px; display: inline-block;">We are officially certified by the USPS to produce privately printed certified mail barcodes and tracking numbers. Using Intelligent Mail Package Barcodes (IMpb), we offer proof of induction into the USPS mail stream, with the first scan confirming the mail piece's placement into USPS custody. <br /> <br /> Our integration with USPS allows us to track each mail piece throughout the delivery cycle, providing multiple scan events, including the final delivery scan (if available).</div>`,
      imgSrc: option2,
      icon: reward,
    },
    {
      id: 3,
      title: "Dynamic Tracking Portal",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Our tracking portal offers:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;">Real-time monitoring of letters and critical documents</li>
        <li style="padding: 0px 15px; font-size: 14px;">Viewing of letter images and Return Receipt Electronic (RRE) images</li>
        <li style="padding: 0px 15px; font-size: 14px;">Access to detailed tracking information</li>
      </ul> 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">This becomes crucial when you need to show proof of mailing or delivery. Additionally, we provide mailing affidavit services for clients who require documented proof of mailing events.
      </div>`,
      imgSrc: option3,
      icon: monitor,
    },
    {
      id: 4,
      title: "Flexible Mailing Options",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Whether you're sending a single letter or a bulk mailing, our services are designed to accommodate your needs:</div> <br />
     <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600"> PDF Extraction Tool:</span> Extract recipient information seamlessly.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600"> Import from File Method:</span>  Easily import mailing lists from your files.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600">Comprehensive API: </span>Integrate our services with your existing systems.</li>
    </ul>
 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">We store all USPS tracking event history, letter images, and mailing affidavit images (as applicable) for up to seven years, ensuring easy access through our interface or API.
      </div>`,
      imgSrc: option4,
      icon: letter,
    },
  ]

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500) // This should match the CSS transition duration
    }
  }

  return (
    <>
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <div className="introSlideCertifiedWrapper">
          <div className="introSlideCertified">
            <div className="slideContent">
              <div className="whatIsCont gutter120">
                  <div className="certifiedsubTitle">
                    Certified Mail Services
                  </div>
                  <h1>
                    Secure, Reliable, and <br /> Trackable Delivery <br />{" "}
                    Solutions
                  </h1>
                  <p className="certifiedTitleDesription">
                    At UploadLetters.com, our Certified Mail services are
                    designed to provide peace of mind for individuals,
                    businesses, legal professionals, and government agencies.
                    Whether you're sending important legal documents, business
                    correspondence, or personal items, our Certified Mail
                    services offer the proof of mailing and tracking that you
                    need.
                  </p>
                  <div className="btnHolder getStartedWrapper">
                    <a
                      href="https://app.uploadletters.com/register"
                      className="button intoSlideButton"
                    >
                      <div className="intoSlideButtonText">Get Started</div>
                    </a>
                  </div>
                  <img src={hero} alt="hero"  className="certifiedHero"/>
              </div>
            </div>
          </div>
        </div>

        <div className="whatWeDoSlide" id="what-we-do">
          <div className="slideContent whatWeDoContBG">
            <div className=" whatIsContMain ">
              <div className="whatWeDoTitleBlock">
                <div>
                  <h1 className="whatWeDocertifiedsubTitle">
                    What is Certified Mail?
                  </h1>
                </div>
                <div className="whatWeDoDescription">
                  Certified Mail is a USPS service that provides proof of
                  mailing and delivery (or attempted delivery), including a
                  receipt for the sender and a signature from the recipient upon
                  delivery. This creates a verifiable record, confirming the
                  date and time of delivery or attempted delivery, making it an
                  essential service for important and sensitive communications.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="whoUsesCont" style={{ background: "#f2f3fc" }}>
          <h1 className="whatIsTitle">Who Uses Certified Mail?</h1>
          <div className="whoUsesContBlock">
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Legal Professionals</div>
                <img src={legal} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
                To ensure court documents, legal notices, or subpoenas are
                delivered with verifiable proof.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Businesses</div>
                <img src={business} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
                For sending contracts, legal documents, tax records, and
                compliance notifications.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Government Agencies</div>
                <img src={government} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
                To send official notices, compliance documents, and other
                critical mailings.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Individuals</div>
                <img src={individuals} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
                For personal documents like tax returns, official complaints, or
                valuable items that require proof of mailing.
              </div>
            </div>
          </div>
        </div>
        <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
            <div className="certifiedAnimationContBG"></div>
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                  Our Certified Mail Offerings
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`imageContainer ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mainContainer whatIsCont"
          style={{ background: "#f2f3fc" }}
        >
          <h2 className="whatIsTitle">Why Choose Us?</h2>
          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={dynamic} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Dynamic Tracking:</span> <br />
                Benefit from real-time tracking and detailed mailing records.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={competetive} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Competitive Pricing:</span>{" "}
                <br />
                Get the best value for high volumes of Certified Mail.
              </div>
            </div>
          </div>

          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={service} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Next Day Service:</span> <br />
                All orders placed by 2:00 PM CST are mailed the very next
                business day.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={secure} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">
                  Reliable and Secure Handling:
                </span>{" "}
                <br />
                Ensure your documents are mailed quickly with proof of mailing.
              </div>
            </div>
          </div>
        </div>
        <div className="mainContainer gutter60">
        <h1 className="bannerTitle">At UpoadLetters.com, we are dedicated to providing secure and reliable Certified Mail services.<br /> <span className="bannerSubtitle">Contact us today to learn more about how we can assist you with your mailing requirements.</span>
        </h1>
          <ABanner />
        </div>
      </MainLayout>
    </>
  )
}

CertifiedMail.defaultProps = {
  location: {},
}

export default CertifiedMail

export const Head = () => (
  <Seo
    title="UploadLetters.com - Certified Mail"
    description="Trust UploadLetters.com for online mailing. Explore Certified, First-Class, and Priority Mail solutions, redefining the mailing experience."
  />
)
